
<template>
  <div class="page">
    <div class="center">
      <el-form :model="ruleForm"
               :rules="rules"
               ref="ruleForm"
               label-width="100px"
               class="demo-ruleForm">

        <div class="bott">

          <div class="left">
            <el-form-item label="站点MAC"
                          prop="device">
              <el-input type="text"
                        v-model="ruleForm.device"
                        placeholder="00:00:00:00:00:00"
                        id="device"
                        clearable>
              </el-input>
            </el-form-item>
            <el-form-item label="设备名称"
                          prop="device_name">
              <el-input type="text"
                        v-model="ruleForm.device_name"
                        placeholder="气象设备名称"
                        id="device_name">
              </el-input>
            </el-form-item>
            <el-form-item label="型号"
                          prop="device_typeEnd">
              <select v-model="ruleForm.device_typeEnd"
                      @change="selectType($event)">
                <option v-for="item in device_typeData"
                        :key="item.id"
                        :value="item.type">{{item.type}}</option>
              </select>
            </el-form-item>
            <el-form-item label="备注信息"
                          prop="remarks">
              <el-input type="textarea"
                        :rows="10"
                        v-model="ruleForm.remarks"
                        placeholder="可对站点安装情况、附件环境、用途，以及其他事项进行补充说明"
                        id="device_name">
              </el-input>
            </el-form-item>
          </div>

          <div class="cen">

            <el-form-item label="地址" prop="address">
              <el-input placeholder='详细地址' v-model="ruleForm.address" clearable></el-input>
            </el-form-item>
            <el-form-item label="经度" prop="lng">
              <el-input type="text" required v-model="ruleForm.lng" placeholder="经度" id="position" clearable>
              </el-input>
            </el-form-item>
            <el-form-item label="纬度" prop="lat">
              <el-input type="text" v-model="ruleForm.lat" placeholder="请输入纬度" id="position" clearable>
              </el-input>
            </el-form-item>
            <el-form-item label="定位">
              <el-button type="primary" @click="hideElement(1)" icon="el-icon-aim">定位</el-button>
            </el-form-item>
          </div>

          <div class="right">
            <el-form-item label="海拔高度"
                          prop="alt">
              <el-input type="text"
                        v-model="ruleForm.alt"
                        placeholder="默认海平面"
                        id="position"
                        clearable>
              </el-input>
            </el-form-item>
            <el-form-item label="安装高度"
                          prop="ele">
              <el-input type="text"
                        placeholder="设备安装离地高度"
                        v-model="ruleForm.ele"
                        id="position"
                        clearable>
              </el-input>
            </el-form-item>

          </div>

        </div>
        <el-form-item>
          <el-button v-if="load" @click="updata()" type="success">确认并退出</el-button>
          <el-button v-else  @click="create()" type="success">保 存</el-button>
        </el-form-item>

      </el-form>

      <div class="mapbox"
           v-if="isVisible">
        <div class="mat">

          <div class="nav">
            <img src="@/assets/svg/gis-add-mapbox.svg"
                 alt="">
            <span>选择位置信息</span>
          </div>
          <h4>在地图上准确定位您的设备</h4>

          <div class="map">
            <el-amap vid="amapDemo"
                     :plugin="plugin"
                     :events="events"
                     :center="center"
                     :zoom="zoom">
              <el-amap-marker v-if="position"
                              :position.sync="position"></el-amap-marker>
            </el-amap>
          </div>

          <div class="btn">
            <el-button type="danger"
                       @click="hideElement(-1)">取消</el-button>
            <el-button type="success"
                       @click="hideElement(0)">保存</el-button>
          </div>
        </div>

      </div>

    </div>
  </div>
</template>

<script>
import axios from 'axios';
import $cookies from "vue-cookies";
import { $device } from "@/services";
export default {
  data () {
    var that = this
    return {
      load: false,//新增修改
      center: [116.40, 39.90],
      zoom: 10,
      loaded: false,
      isVisible: false,

      device_typeData: [ //类别选择数据或者从后台获取数据
        { id: 1, type: "CF8型智能气象站" },
        { id: 2, type: "WJ-A型气象站" },
        { id: 3, type: "WJ-B型气象站" },
        { id: 4, type: "其他型号气象站" }
      ],

      currentUserName: "",
      position: null,
      ruleForm: {
        device: "",
        device_name: '',
        device_typeEnd: "NONE",
        remarks: '',
        address: '',
        lng: null,
        lat: null,
        alt: null,
        ele: null,
        province: '',
        city: '',
        area: '',
        country: false,
      },
      device_id: '',
      rules: {
        device: [
          { required: true, message: '请输入MAC地址', trigger: 'blur' },
          { required: true, pattern: /^([a-fA-F0-9]{2}:){5}([a-fA-F0-9]{2})$/, message: '请正确输入MAC地址', trigger: 'blur' },
        ],
        device_name: [
          { required: true, message: '请输入名称', trigger: 'blur' },
        ],
        device_typeEnd: [
          { required: true, message: '请选择型号', trigger: 'blur' },
        ],
        address: [
          { required: true, message: '请输入地址', trigger: 'blur' },
        ],
        lng: [
          { required: true, message: '请输入经度', trigger: 'blur' },
        ],
        lat: [
          { required: true, message: '请输入纬度', trigger: 'blur' },
        ],
      },

      plugin: [{
        enableHighAccuracy: true,   //是否使用高精度定位，默认true
        timeout: 100,               //超过10秒后停止定位，默认：无穷大
        convert: true,          //自动偏移后的坐标为高德坐标，默认：true
        showButton: true,       //显示定位按钮，默认：true
        buttonPosition: 'RB',  //定位按钮停靠位置，默认'LB'，左下角
        showMarker: false,      //定位成功后在定位到的位置显示标记，默认：true
        showCircle: false,      //定位成功后用圆圈表示定位精度范围，默认：true
        panToLocation: true,   //定位成功后将定位到的位置作为地图中心点，默认true
        zoomToAccuracy: true,   //定位成功后调整地图视野范围使定位位置及精度范围视野内可见，默认：false
        extensions: "all",
        pName: "Geolocation",     //AMap-Geolocation 定位插件
        events: {
          init (o) {
            // o 是高德地图定位插件实例
            o.getCurrentPosition((status, result) => {
              if (result && result.position) {
                that.center = [result.position.lng, result.position.lat]
                that.position = [result.position.lng, result.position.lat]
                that.loaded = true;
                that.zoom = 14;
                that.$nextTick();
              }
            })
          }
        }
      }],
      events: {
        init: (o) => {
          o.getCity((result) => {
            console.log(result)
          })
        },
        click: (e) => {
          that.center = [e.lnglat.lng, e.lnglat.lat]
          that.position = [e.lnglat.lng, e.lnglat.lat]
        }
      },
    };
  },
  created () {
    this.currentUserName = $cookies.get("currentUserName");
    // this.currentUserName = "admin"
    this.ruleForm.device_typeEnd = this.device_typeData[0].type
  },
  mounted () {
    this.setForm()
  },
  destroyed () {
    sessionStorage.removeItem("cur")
  },
  methods: {
    disptpickerHandle (event, value) {
      this.ruleForm[value] = event.value
    },
    selectType (event) {
      this.ruleForm.device_typeEnd = event.target.value; //获取option对应的value值 select_class_id是后台约定的提交数据的名称
    },
    hideElement (value) {
      if (value == 1) {
        this.isVisible = true
      } else if (value == 0) {
        this.sendGetRequest()
        this.isVisible = false
      } else {
        this.isVisible = false
      }
    },
    sendGetRequest () {
      let url = `https://restapi.amap.com/v3/geocode/regeo?output=JSON&location=${this.position}&key=0ca7fee066a1fa548ebef6e4730fb4c9&extensions=base&batch=false`
      axios.get(url).then(res => {
        this.ruleForm.address = res.data.regeocode.formatted_address
        this.ruleForm.lng = this.position[0]
        this.ruleForm.lat = this.position[1]
      }).catch(error => {
        console.log(error);
      });
    },
    clear () {
      this.$refs.ruleForm.resetFields() //清除按钮 -  重置表单功能
    },
    create () {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          return $device.setConnectData({
            device: this.ruleForm.device,
            device_name: this.ruleForm.device_name,
            device_type: this.ruleForm.device_typeEnd,
            position_lng: this.ruleForm.lng,
            position_lat: this.ruleForm.lat,
            position_alt: this.ruleForm.alt,
            address: this.ruleForm.address,
            remarks: this.ruleForm.remarks,
            currentUserName: this.currentUserName,
            userId: $cookies.get('userInfo').id
          }).then((res) => {
            if (res.result === 0) {
              this.device = null
              this.$message({
                message: res.error_msg,
                type: "warning",
              });
              return
            }
            res.result === 1 ? this.success() : this.anomaly()
          });
        }
      });
    },
    success () {
      this.$refs.ruleForm.resetFields()
      this.$router.push({ name: "gislist", });
      this.$message({
        message: "操作成功",
        type: "success",
      });
    },
    anomaly () {
      this.$message.error('操作异常!');
    },
    setForm () {
      this.$nextTick(() => {
        let cur = JSON.parse(sessionStorage.getItem('cur'))
        if (cur) {
          this.load = true
          this.ruleForm.device = cur.mac
          this.device_id = cur.id
          this.ruleForm.device_name = cur.device_name
          this.ruleForm.device_typeEnd = cur.device_type
          this.ruleForm.lng = cur.position[0]
          this.ruleForm.lat = cur.position[1]
          this.ruleForm.alt = cur.position_alt
          this.ruleForm.address = cur.address
          this.ruleForm.remarks = cur.remarks
          this.position = [cur.position[0], cur.position[1]]
          this.sendGetRequest()
        }
      })
    },
    updata () {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          return $device.upConnectData({
            device_id: this.device_id,
            device: this.ruleForm.device,
            device_name: this.ruleForm.device_name,
            device_type: this.ruleForm.device_typeEnd,
            position_lng: this.ruleForm.lng,
            position_lat: this.ruleForm.lat,
            position_alt: this.ruleForm.alt,
            address: this.ruleForm.address,
            remarks: this.ruleForm.remarks,
          }).then((res) => {
            res.result === 1 ? this.success() : this.anomaly()
          });
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
/deep/ .amap-container {
  border-radius: 20px;
}
.center {
  width: 1420px;
  margin: 0 auto;
  box-sizing: border-box;
  // background-color: #0ab1e4;
}
@media (max-width: 1920px) {
  .center {
    width: 1420px;
  }
}

@media (max-width: 1680px) {
  .center {
    width: 1280px;
  }
}

@media (max-width: 1280px) {
  .center {
    width: 100%;
  }
}
.page {
  width: 100%;
  height: 100%;
  position: relative;
  .top {
    color: #666;
    padding-bottom: 30px;
    border-bottom: 1px solid #e3e3e3;
    h1 {
      font-size: 40px;
      margin: 15px 0;
    }
    h3 {
      font-size: 20px;
    }
    p {
      padding-top: 15px;
      font-size: 14px;
    }
  }

  .demo-ruleForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    .bott {
      width: 100%;
      display: flex;
      margin-top: 30px;
      // justify-content: center;
      > :nth-child(1) {
        flex: 1;
        display: flex;
        flex-direction: column;
        > :nth-child(3) {
          > :nth-child(2) {
            > :nth-child(1) {
              width: 100%;
              height: 40px;
              box-sizing: border-box;
              border: 1px solid #6666662a;
              padding: 0 8px;
              color: #666;
              // appearance: none;
            }
            > :nth-child(1):focus {
              outline: 1px solid rgba(45, 136, 255, 0.671);
            }
          }
        }
      }
      > :nth-child(z) {
        > :nth-child(1) {
          > :nth-child(2) {
            display: flex;
            height: 100%;
            align-items: center;
          }
        }
      }
      > :nth-child(2) {
        flex: 1;
        display: flex;
        flex-direction: column;
        padding: 0 50px;
        > :nth-child(2) {
          > :nth-child(2) {
            > :nth-child(1) {
              display: flex;
              flex-direction: column;
              > :nth-child(n) {
                margin-bottom: 10px;
                > :nth-child(n) {
                  width: 200px !important;
                }
              }
            }
          }
        }
      }
      > :nth-child(3) {
        flex: 1;
        display: flex;
        flex-direction: column;
      }
      > :nth-child(n) {
        > :nth-child(n) {
          > :nth-child(1) {
            font-size: 20px;
            text-align: center;
            width: 180px !important;
          }
        }
      }
    }
    > :nth-child(2) {
      > :nth-child(1) {
        > :nth-child(n) {
          font-size: 24px;
        }
      }
    }
  }
  .mapbox {
    position: absolute;
    top: -15px;
    left: 0;
    width: 100%;
    background-color: #000000ce;
    height: calc(100vh - 50px);
    .mat {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 520px;
      background-color: #fff;
      border: 1px solid rgba(0, 0, 0, 0.3);
      color: #666;
      text-align: center;
      display: flex;
      flex-direction: column;
      .nav {
        padding: 15px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 24px;
        img {
          height: 50px;
        }
      }

      h4 {
        text-align: center;
        padding: 0 0 10px 0;
      }
    }
    .map {
      margin: 0 auto;
      height: 500px;
      width: 500px;
    }
  }
}
.btn {
  display: flex;
  justify-content: center;
  margin: 20px;
}
</style>